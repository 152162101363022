import React from 'react';
import { Link } from 'react-router-dom';

const examPrepList = [
  { name: 'Principles of Real Estate I - Texas Practice Test', exam: 'https://chatgpt.com/g/g-efqMCf9dl-principles-of-real-estate-i-texas-practice-test'},
  { name: 'Principles of Real Estate II - Texas Practice Test', exam: 'https://chatgpt.com/g/g-yEbTlwLrN-principles-of-real-estate-ii-texas-practice-test' },
  { name: 'Law of Agency - Texas Real Estate Practice Test', exam: 'https://chatgpt.com/g/g-kD8k3wdrK-law-of-agency-texas-real-estate-practice-test' },
  { name: 'Law of Contracts - Texas Real Estate Practice Test', exam: 'https://chatgpt.com/g/g-Y4jJ89NVF-law-of-contracts-texas-real-estate-practice-test'},
  { name: 'Promulgated Contract Forms - Texas Practice Test', exam: 'https://chatgpt.com/g/g-Yp9JxvyQB-promulgated-contract-forms-texas-practice-test' },
  { name: 'Real Estate Finance - Texas Practice Test', exam: 'https://chatgpt.com/g/g-eqkidl07m-real-estate-finance-texas-practice-test' },
];

const GetLicensedPage = () => {
  return (
    <main id="team" className="container mt-5">
      <h1>Welcome Future Real Estate Professionals! 🌟</h1>
      <p>We are thrilled to be a part of your journey towards becoming a licensed real estate agent. At Cloud Team Realty, we believe in the power of education and the importance of support throughout this exciting process. That's why our Cloud Team has developed specialized exam prep chatbots inside ChatGPT to help you learn and pass your exams with confidence. 📚🤖</p>
      <h2>Why We Created These Exam Prep chatGPTs</h2>
      <p>We know how challenging preparing for the real estate exam can be. Our team is dedicated to providing innovative and modern tools to help you succeed. While we are not an Approved Qualifying Real Estate Provider, nor do we intend to be, we are passionate about supporting aspiring real estate professionals in unique ways.</p>
      <p>For official TREC qualifying courses and providers, please visit the <a href="https://www.trec.texas.gov/education/approved-qualifying-real-estate-courses" target="_blank" rel="noreferrer">TREC Approved Qualifying Real Estate Courses page.</a></p>
      <p><Link to='/contact' className="btn btn-primary">Please give us feedback</Link> on these test prep chatbots on how we could make them better! Scroll down and click the green button on the test you want to practice.</p>
      <h2 className="mb-4">Free Exam Prep chatGPTs</h2>
      <p>Practice makes perfect! Our free exam prep chatGPTs are here to assist you in mastering the material. Click on the links below to start practicing:</p>
      <div className="row">
        {examPrepList.map(member => (
          <div key={member.name} className="col-lg-3 col-md-4 col-sm-6 mb-4">
            <div className="card text-bg-dark text-center">
              <div className="card-body">
                <h5 className="card-title">{member.name}</h5>
                <p className="card-text text-center"><a href={member.exam} target="_blank" rel="noreferrer" className="btn btn-success">Take this practice test FREE</a></p>
              </div>
            </div>
          </div>
        ))}
      </div>
      <p>This “Learning Guide To Real Estate Exam Prep chatGPTs” is more than a playbook—it’s your indispensable toolkit for getting the most out of your learning journey!</p>
      <p><Link to='https://cdn.cloudteamtx.com/marketing/Real%20Estate%20Test%20Learning%20Guide.pdf' className="btn btn-primary">Click Here to Download the FREE Guide</Link> </p>
      <p></p>
      <p>We hope these resources help you feel more prepared and confident as you approach your exam date. Best of luck, and remember, we’re here to support you every step of the way!</p>
     </main>
  );
};

export default GetLicensedPage;
