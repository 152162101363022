import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './styles.css';
import Header from './components/Header';
import Footer from './components/Footer';
import MainPage from './components/MainPage';
import ContactUsPage from './components/ContactUsPage';
import TeamPage from './components/TeamPage';
import AgentListPage from './components/AgentListPage';
import CareersPage from './components/CareersPage';
import SystemPage from './components/SystemPage';
import TermsOfServicePage from './components/TermsOfServicePage';
import PrivacyPolicyPage from './components/PrivacyPolicyPage';
import FairHousing from './components/FairHousing';
import GetLicensedPage from './components/GetLicensedPage';
const App = () => {
  return (
    <Router>
      <div>
        <Header />
        <Routes>
          <Route path="/" element={<MainPage/>} />
          <Route path="/contact" element={<ContactUsPage/>} />
          <Route path="/team" element={<TeamPage/>} />
          <Route path="/agents" element={<AgentListPage/>} />
          <Route path="/careers" element={<CareersPage/>} />
          <Route path="/getlicensed" element={<GetLicensedPage/>} />
          <Route path="/terms" element={<TermsOfServicePage/>} />
          <Route path="/privacy" element={<PrivacyPolicyPage/>} />
          <Route path="/system" element={<SystemPage/>} />
          <Route path="/fairhousing" element={<FairHousing/>} />
          <Route path="/fairhousing.html" element={<FairHousing/>} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
};

export default App;