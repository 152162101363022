import React from 'react';
import { HashLink } from 'react-router-hash-link';

const teamMembers = [
  { name: 'Rashidah', role: 'REALTOR®', image: 'rashida_realtor.png',phone:'(346) 460-6890', phone2:'(832) 563-0546' },
  { name: 'Carrie', role: 'REALTOR®', image: 'carrie_realtor.png',phone:'(737) 273-3144', phone2:'(512) 256-3765' },
  { name: 'Savanna', role: 'REALTOR®', image: 'savanna_realtor.png',phone:'(817) 442-2241', phone2:'(817) 524-9192' },
  { name: 'Sheryl', role: 'REALTOR®', image: 'sheryl_realtor.png',phone:'(817) 710-8412', phone2:'(817) 946-4548' },
  { name: 'Amanda', role: 'REALTOR®', image: 'amanda.png',phone:'(346) 460-6892', phone2:'(713) 501-9930' },
  { name: 'Bobby', role: 'REALTOR®', image: 'bobby.png',phone:'(346) 460-6265', phone2:'(713) 498-2327' },
  { name: 'Denise', role: 'REALTOR®', image: 'denise.png',phone:'(346) 460-6896', phone2:'(936) 203-6981' },
  { name: 'Gina', role: 'REALTOR®', image: 'gina.png',phone:'(346) 460-6891', phone2:'(832) 755-5785' },
  { name: 'Ethan', role: 'REALTOR®', image: 'ethan.png',phone:'(346) 460-6240', phone2:'(832) 492-2780' },
  { name: 'Karen', role: 'REALTOR®', image: 'karen.png',phone:'(346) 460-6897 ', phone2:'(361) 920-3664' },
  { name: 'Neel', role: 'REALTOR®', image: 'neel_realtor.png',phone:'(346) 633-4100', phone2:'(281) 850-6177' },
  { name: 'Jeff', role: 'REALTOR®', image: 'jeff_realtor.png',phone:'(346) 460-6898', phone2:'(682) 255-2745' },
];

const TeamPage = () => {
  return (
    <main id="team" className="container mt-5">
      <h1>Meet the Cloud Team REALTORS®:<br/>Empowering Excellence in Real Estate</h1>
      <p>At Cloud Team Realty, our agents are not just professionals; they are innovators in the real estate industry. With the cutting-edge technologies and tools we’ve implemented, our agents are reclaiming their time, allowing them to focus on what they do best—providing exceptional service and results for their clients. These REALTORS® are thriving in an environment that prioritizes efficiency, client satisfaction, and continuous improvement. They are experts in their markets, dedicated to finding the perfect home, commercial building or land for every client, and committed to making each transaction seamless and successful.</p>
      <p>Whether you’re buying or selling, you can trust that you’re in capable hands with our team. Their success is built on a foundation of deep market knowledge, unwavering integrity, and a genuine passion for real estate. When you work with a Cloud Team Realty agent, you’re not just getting a transaction; you’re gaining a trusted advisor who will guide you every step of the way.</p>
      <div className="row">
        {teamMembers.map(member => (
          <div key={member.name} className="col-lg-2 col-md-3 col-sm-4 mb-4">
            <div className="card text-bg-dark text-center">
              <img src={member.image} className="card-img-top" alt={member.name} />
              <div className="card-body">
                <h5 className="card-title">{member.name}</h5>
                <p className="card-text text-center">{member.role}<br/>{member.phone}<br/>{member.phone2}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
      <p>Are you a real estate professional looking to elevate your career? Cloud Team Realty offers a dynamic environment where agents are supported with state-of-the-art tools, continuous training, and a collaborative team culture. If you’re ready to take your business to the next level, <HashLink smooth to='/careers#openroles'>check out our careers section</HashLink> and discover the opportunities awaiting you.</p>
    </main>
  );
};

export default TeamPage;